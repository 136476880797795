import AppInstance from "./App";
import moment from "moment";

function token_is_valid(t) {
  //eventually, try validate exp
  return !!t;
}

function unit_convert(obj) {
  //obj has a val and a units
  if(obj.units == "kPa") {
    obj.val = obj.val * 0.145038;
    obj.units = "PSI";
  } else if(obj.units == "°C") {//ohh how I hate that they include the degree symbol. I don't blame them, but I don't like it either.
    obj.val = (obj.val*1.8)+32;
    obj.units = "°F"
  } else if(obj.units == "l/h") {
    obj.val = obj.val * 0.264172;
    obj.units = "g/h";
  }
  return obj;
}

function API() {
  const self = this;

  //TODO: store token, fetch token, renew if nearing expiry, check session and localStorage
  //TODO: handle no token, 401, as redirect to /auth/login

  self.token = localStorage.getItem("AUTH_TOKEN");
  self.logged_in = token_is_valid(self.token);

  self.on = function(name, cb) {
    if(name=='401') { self.on_401 = cb; }
    if(name=='login') { self.on_login = cb; }
  }
  self.on_401 = function() { }
  self.on_login = function() { }

  self.do_gql_request = function do_gql_request(query, vars) {
    return fetch("/api/v1/graphql", {
      method: "POST",
      headers: {
        "Authorization": self.token ? "Bearer "+self.token : "UNAUTH",
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "query": query,
        "variables": vars
      })
    }).then(function(resp){
      if(resp.status == 401) {
        self.logged_in = false;
        self.token = null;
        self.on_401();
        return Promise.reject("UNAUTH");
      } else {
        return resp;
      }
    })
  }

  self.fetch_dashboard = function fetch_dashboard() {
    return self.do_gql_request(`
      query {
        activeUser {
          id name
          customer { id name }
        }
        vehicles {
          id name kind description lastContact status
          gps {
            at lat lng
            near { city state}
          }
          lastTriconTime
          lastTricon(id: [206, 137, 22, 0, 10, 136, 1]) {
            idx
            text
            nice
          }
          lastJ1939(id: ["190_0_255", "92_0_255", "110_0_255", "94_0_255", "100_0_255", "183_0_255"]) {
            val
            spn { spn slot { unit } }
          }
          lastJ1939Time
        }
      }
    `, {}).then(function (resp) {
      if(resp.status === 200) {
        return resp.json().then(raw_results => {
          return {
            "user": {
              "id": raw_results.data.activeUser.id,
              "name": raw_results.data.activeUser.name,
              "company_id": raw_results.data.activeUser.customer.id,
              "company_name": raw_results.data.activeUser.customer.name
            },
            "vehicles": raw_results.data.vehicles.map(v => ({
              id: v.id,
              name: v.name,
              description: v.description,
              status: v.status,
              kind: v.kind,
              lastContact: v.lastContact ? moment.utc(v.lastContact) : null,
              triconTime: v.lastTriconTime ? moment.utc(v.lastTriconTime) : null,
              tricon: v.lastTricon.reduce((accum, val) => {
                accum['tri_'+val.idx] = { idx: val.idx, text: val.text, nice: val.nice };
                return accum;
              }, {}),
              j1939Time: v.lastJ1939Time ? moment.utc(v.lastJ1939Time) : null,
              j1939: v.lastJ1939.reduce((accum, val) => {
                //spn 190 => engine speed, 92 => engine load, 247 => hours, 110 => coolant tmp, 94 => fuel psi, 100 => oil psi, 183 => fuel rate, 168 => voltage
                accum['spn_'+val.spn.spn] = unit_convert({ val: val.val, units: val.spn.slot.unit });
                return accum;
              }, {}),
              gps: v.gps ? {
                "at": moment.utc(v.gps.at),
                "lat": v.gps.lat,
                "lng": v.gps.lng,
                "near": v.gps.near ? v.gps.near.city+" "+v.gps.near.state : "USA"
              } : null,
            }))
          };
        })
      } else if(resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("HTTP ERROR "+resp.status);
      }
    })
  };

  self.fetch_vehicle = function fetch_vehicle(id) {
    return self.do_gql_request(`
      query ($id: Int!) {
        vehicle(id: $id) {
          id name kind description lastContact
          gps {
            at lat lng
            near { city state}
          }
          lastTriconTime
          lastTricon(latestOnly: false) {
            idx
            text
            nice
            spec { rawSection niceSection shortName }
          }
          lastJ1939Time
          lastJ1939Msg {
            can0: canN(idx: 0) {
              hexId
              hexData
              j1939 {
                pgn { pgn name abbr }
                src { addr name }
                dst { addr name }
                spns(excludeDefaults: true) {
                  val
                  meaning
                  spn {
                    spn
                    name
                    slot { unit }
                  }
                }
              }
            }
          }
        }
      }
    `, {id: id}).then(function (resp) {
      if(resp.status === 200) {
        return resp.json().then(raw_results => {
          let v = raw_results.data.vehicle;
          return v ? {
            id: v.id,
            name: v.name,
            kind: v.kind,
            description: v.description,
            lastContact: v.lastContact ? moment.utc(v.lastContact) : null,
            triconTime: v.lastTriconTime ? moment.utc(v.lastTriconTime) : null,
            tricon: v.lastTricon?.reduce((accum, val) => {
              accum['tri_'+val.idx] = {
                idx: val.idx,
                text: val.text,
                nice: val.nice,
                section: val.spec.rawSection,
                niceSection: val.spec.niceSection,
                niceName: val.spec.shortName
              };
              return accum;
            }, {}),
            j1939Time: v.lastJ1939Time ? moment.utc(v.lastJ1939Time) : null,
            j1939Snapshot: v.lastJ1939Msg?.can0 ? v.lastJ1939Msg?.can0.map(msg => ({
                id: msg.hexId,
                data: msg.hexData,
                has_j1939: !!(msg.j1939?.pgn),
                pgn_id: msg.j1939?.pgn?.pgn,
                pgn_abbr: msg.j1939?.pgn?.abbr,
                pgn_name: msg.j1939?.pgn?.name,
                src_addr: msg.j1939?.src?.addr,
                src_name: msg.j1939?.src?.name,
                dst_addr: msg.j1939?.dst?.addr,
                dst_name: msg.j1939?.dst?.name,
                spns: msg.j1939?.spns?.map(spn => unit_convert({
                  val: spn.val,
                  meaning: spn.meaning,
                  units: spn.spn.slot.unit,
                  name: spn.spn.name,
                  id: spn.spn.spn,
                })) || []
              })) : [],
            gps: v.gps ? {
              "at": moment.utc(v.gps.at),
              "lat": v.gps.lat,
              "lng": v.gps.lng,
              "near": v.gps.near ? v.gps.near.city+" "+v.gps.near.state : "USA"
            } : null,
          } : null;
        });
      } else if(resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    })
  };

  self.fetch_user = function fetch_user() {
    return self.do_gql_request(`
query {
  activeUser {
    id name
    customer { id name }
  }
}
    `, {}).then(function (resp) {
      if(resp.status === 200) {
        return resp.json().then(raw_results => {
          return {
            "id": raw_results.data.activeUser.id,
            "name": raw_results.data.activeUser.name,
            "company_id": raw_results.data.activeUser.customer.id,
            "company_name": raw_results.data.activeUser.customer.name
          };
        })
      } else if(resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    })
  };

  self.logout = function logout() {
    self.logged_in = false;
    self.token = "";
    localStorage.removeItem("AUTH_TOKEN");
    self.on_401();
  };

  self.try_login = function try_login(un, pw) {
    return fetch("/api/v1/login", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "username": un,
        "password": pw,
        "remember": true,
      })
    }).then(function(resp){
      if(resp.status === 200) {
        return resp.json().then(res=>{
          if(res.success) {
            self.logged_in = true;
            self.token = res.token;
            localStorage.setItem("AUTH_TOKEN", self.token);
            self.on_login();
            return { "success": true, "message": "Logged in"};
          } else {
            return { "success": false, "message": res.message};
          }
        });
      } else {
        return {"success": false, "message": "Request Failed, unknown reason"}
      }
    })
  };

  self.try_change_password = function try_change_password(curr_pw, new_pw) {
    return fetch("/api/v1/change_password", {
      method: "POST",
      headers: {
        "Authorization": self.token ? "Bearer "+self.token : "UNAUTH",
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "password": curr_pw,
        "new_password": new_pw,
      })
    }).then(function(resp){
      if(resp.status === 200) {
        return resp.json().then(res=>{
          if(res.success) {
            return { "success": true, "message": "Password changed"};
          } else {
            return { "success": false, "message": res.message};
          }
        });
      } else {
        return {"success": false, "message": "Request Failed, unknown reason"}
      }
    })
  };
}


export default API;
